import { Link } from 'gatsby';
import React, { FunctionComponent } from 'react';
import Image from '~/components/Image';

interface DonationsHeroProps {
  hero: {
    subheading: string;
    heading: string;
    content: string;
    button: {
      url: string;
      title: string;
    };
    image: string;
  };
}

const DonationsHero: FunctionComponent<DonationsHeroProps> = ({ hero }) => {
  return (
    <section className="py-12 md:py-20">
      <div className="container">
        <div className="grid gap-8 md:grid-cols-2 md:gap-0">
          <div>
            <Image data={hero?.image} className="w-full" loading="eager" />
          </div>
          <div className="flex items-center md:pl-10 xl:pl-20">
            <div>
              <div
                className="font-medium text-red"
                dangerouslySetInnerHTML={{
                  __html: hero.subheading,
                }}
              />
              <h1
                className="max-w-md md:max-w-md"
                dangerouslySetInnerHTML={{ __html: hero.heading }}
              />
              <div
                className="max-w-md mb-6 md:max-w-sm-md text-navy"
                dangerouslySetInnerHTML={{ __html: hero.content }}
              />
              <Link to={hero.button?.url} className="btn">
                {hero.button?.title}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DonationsHero;
