import { graphql } from 'gatsby';
import React, { FunctionComponent, ReactElement } from 'react';
import FlexibleContent from '~/components/FlexibleContent';
import SEO from '~/components/Global/SEO';
import DonationsHero from '~/components/Hero/DonationsHero';
import Layout from '~/components/Layout';
import { DonationsPageQuery } from '../graphql-types';

interface DonationsProps {
  data: DonationsPageQuery;
}

const DonationsFC: FunctionComponent<DonationsProps> = ({
  data,
}): ReactElement => {
  const {
    title,
    donationsPage: { donationsHero },
    layouts,
  } = data.wpPage;
  return (
    <Layout>
      <SEO title={title} />
      <main>
        <DonationsHero hero={donationsHero} />
        <FlexibleContent pageName="donations" layouts={layouts} />
      </main>
    </Layout>
  );
};

export default DonationsFC;

export const query = graphql`
  query DonationsPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      donationsPage {
        donationsHero {
          subheading
          heading
          content
          button {
            url
            title
          }
          image {
            ...Image
          }
        }
      }
      layouts {
        layouts {
          __typename
          ... on WpPage_Layouts_Layouts_TwoColImageText {
            subheading
            heading
            headingWidth
            content
            contentWidth
            image {
              ...Image
            }
            imageType
            animationName
            imagePosition
            backgroundColour
            # alertNotice
            # noticeContent
            contentLink
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_SecurityPanel {
            icon {
              ...Image
            }
            heading
            content
            # link {
            #   title
            #   url
            # }
          }
          #... on WpPage_Layouts_Layouts_donations_purpose {
          # id
          # heading
          # content
          # animation {
          #   source_url
          #   localFile {
          #     id
          #     url
          #   }
          # }
          #}
          ... on WpPage_Layouts_Layouts_DonationsBeforeAfter {
            heading
            content
            noticeContent
            tab {
              tabName
            }
          }
          ... on WpPage_Layouts_Layouts_RelatedPages {
            heading
            page {
              heading
              description
              link {
                title
                url
              }
              image {
                ...Image
              }
            }
          }
          ... on WpPage_Layouts_Layouts_UseCasesGrid {
            subheading
            heading
            content
            link {
              title
              url
            }
          }
          ... on WpPage_Layouts_Layouts_TwoColTextAndFullWidthImage {
            subheading
            heading
            content
            image {
              ...Image
            }
            backgroundColour
          }
          ... on WpPage_Layouts_Layouts_Cta {
            heading
            content
            button {
              title
              url
            }
            image {
              ...Image
            }
            mobileImage {
              ...Image
            }
            imagePosition
          }
        }
      }
    }
  }
`;
